@use 'variables';

.page-favourites {
    margin: 0 auto 80px auto;
    
    h2 {
        margin: 30px 0;
        font-size: 30px;
    }

    &__grid {
        display: flex;
        margin: 0px -15px;
        flex-wrap: wrap;
        .component-product {
            flex: 0 0 50%;
            margin-bottom: 1.875rem;
            padding: 0px 5px;
            @include variables.for-tablet-landscape-up {
                flex: 0 0 33.33%;
                padding: 0px 10px;
            }
            @include variables.for-desktop-up {
                flex: 0 0 25%;
            }

            &__info {
                @include variables.for-mediumUp-only {
                    &__left {
                        flex: 1 1 0%;
                    }

                    &__right {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 2px;

                        .price {
                            &__label {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    @include variables.for-mediumUp-only {
        &__grid {
            margin: 0px -10px;
        }
    }

    @include variables.for-tablet-landscape-up {
        h2 {
            font-size: 40px;
            margin: 40px 0px;
        }
    }
}
